import React from 'react';
import '../App.css';
import { Container, ListGroup } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';

class WhoWeAre extends React.Component {
    render () {
        return (
        <div className="Section-header Founder-section row" id="OurFounder">
          <h1 style={{width:'100%'}}>Our Founder and Vision</h1>
          <div className="col-xs-6 Founder-Grouper">
            <img
                className="rounded float-left Founder-img"
                src={require('../Images/Tania1.jpeg')}
                alt="Images/Tania1.jpeg"
                />
          </div>
          
          <div className="col-xs-6 Founder-Grouper" >
            <p> Tania has always been a supporter of arts and music education and grew up dreaming of sharing her love of music with others.
Harmony 4 Hamilton was inspired by a variety of events that Tania has participated in over the last few years where the choral community comes together to sing. 
This annual choral concert is designed to give 100% of the ticket sales to the organization we chose to support for the event.
 Being a music teacher, children and music education are very important to not only myself, but the H4H committee as a whole.
I would like to thank the H4H committee for getting behind this event and giving their time and energy to make it successful.  
It is a dream come true to see the choirs come together for an amazing concert of beautiful music and selflessly give back to this great community. </p>
          </div>
          
          <div className="col-xs-6 Founder-Grouper">
            <img
                className="rounded float-right Founder-img"
                src={require('../Images/Tania2.jpeg')}
                alt="Images/Tania2.jpeg"
                />
          </div>
          <div style={{ width:'100%', height:'10px'}}></div>
        </div>
        )
    }
}

export default WhoWeAre;
