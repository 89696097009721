import React from 'react';
import '../App.css';
import Mailto from 'react-protected-mailto'

class ContactUs extends React.Component {
    render () {
        return (
        <div className="Section-header ContactUs-section" id="ContactUs">
          <h1>Contact Us</h1>
          
          <div>
            <p>
            Email us at to: <Mailto
              email='harmony4hamilton@gmail.com'
              headers={
                {subject:'Harmony 4 Hamilton Contact Us'},
                {cc:'andrewlawmoir@gmail.com'}
              }/>
            </p>
          </div>
        </div>
        )
    }
}

export default ContactUs;