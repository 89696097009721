import React from 'react';
import '../App.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom"; 

class Header extends React.Component {
    render () {
        return (
        <Navbar collapseOnSelect expand="lg" /*fixed="top" bg="dark"*/ variant="dark" className="Header-bar">
          <Navbar.Brand href="#home">Harmony 4 Hamilton</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#Event">Event</Nav.Link>
              <Nav.Link href="#OurCharity">An Instrument for Every Child</Nav.Link>
              <Nav.Link href="#Participants">Participants</Nav.Link>
              <NavDropdown title="About Us" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#WhoWeAre">Who We Are</NavDropdown.Item>
                <NavDropdown.Item href="#OurFounder">Our Founder and Vision</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#ContactUs">Contact Us</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        )
    }
}

export default Header;
