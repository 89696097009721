import React from 'react';
import '../App.css';

class Participants extends React.Component {
    render () {
        return (
        <div className="Section-header Participants-section" id="Participants">
          <h1>Participants</h1>
          
          <div>
            <p>
            Our event is made possible by the collaboration of several choirs with a common goal,
                to raise money for a worthy cause. The choirs that will be joining us this year to raise money for
                An Instrument For Every Child are: 
                <a href="https://www.circleofharmony.ca/w/" target="blank"> Circle of Harmony Chorus</a>, 
                <a href="https://www.dofascomalechorus.com/" target="blank"> Dofasco Male Chorus</a>, 
                <a href="https://enchorus.ca/" target="blank"> Enchorus Children's Choir</a>, 
                <a href="https://hamiltonpolice.on.ca/about/hamilton-police-female-choir" target="blank"> Hamilton Police Female Choir</a>, 
                <a href="http://harlequinsingers.com" target="blank"> The Harlequin Singers</a>,
                and 
                <a href="http://www.kokorosingers.com/" target="blank"> Kokoro Singers</a>
                .
            </p>
          </div>
          
          <div style={{ width:'100%', height:'10px'}}></div>
        </div>
        )
    }
}

export default Participants;