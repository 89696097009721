import React from 'react';
import './App.css'; 
import Header from './Components/Header.js'
import SubHeader from './Components/SubHeader.js'
import Event from './Components/Event.js'
import OurCharity from './Components/OurCharity.js'
import Participants from './Components/Participants.js'
import WhoWeAre from './Components/WhoWeAre.js'
import OurFounder from './Components/OurFounder.js'
import ContactUs from './Components/ContactUs.js'

class App extends React.Component {
  render () {
    return (
      <div className="App">
        <Header />
        <SubHeader />
        <Event />
        <OurCharity />
        <Participants />
        <WhoWeAre />
        <OurFounder />
        <ContactUs />
      </div>
    )
  }
}

export default App;
