import React from 'react';
import '../App.css';

class Event extends React.Component {
    render () {
        return (
        <div className="Section-header Event-section" id="Event">
          <h1>Event - notice</h1>
          
          <div>
            <p>
              Due to covid-19 this event has been cancelled.
              {/*
              Purchase your tickets today: 
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="44LX7XRXSN7BN" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
              */}
            </p>
          </div>
          
          <div style={{ width:'100%', height:'10px'}}></div>
        </div>
        )
    }
}

export default Event;