import React from 'react';
import '../App.css';
import { Container, ListGroup } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';

class WhoWeAre extends React.Component {
    render () {
        return (
        <div className="Who-we-are-section Section-header" id="WhoWeAre">
          <h1>Who We Are</h1>
          <ListGroup> 
            <ListGroup.Item variant="light">Tania Sheeler</ListGroup.Item>
            <ListGroup.Item variant="light">Christine Bering</ListGroup.Item>
            <ListGroup.Item variant="light">Lisa Edgcumbe-Vooys</ListGroup.Item>
            <ListGroup.Item variant="light">Kathryn Lee</ListGroup.Item>
            <ListGroup.Item variant="light">Lisa Lingard</ListGroup.Item>
            <ListGroup.Item variant="light">Victoria Moir</ListGroup.Item>
          </ListGroup>
        </div>
        )
    }
}

export default WhoWeAre;
