import React from 'react';
import '../App.css';

class OurCharity extends React.Component {
    render () {
        return (
        <div className="Section-header Charity-section" id="OurCharity">
          <h1>An Instrument For Every Child</h1>

          <div style={{ width:'100%' }}>
            <img
                className="rounded"
                src={require('../Images/ourCharityLogo.jpg')}
                alt=""
                style={{ margin:'auto' }}
                />
          </div>
          
          <div>
            <p>Last year we raised almost $5000 for Citykidz and this year the organization we are supporting is called "an instrument for every child".
            A key initiative of HMC is An Instrument for Every Child, a musical education program that introduces young people, including many disadvantaged children, to creative and improvised music, with instruction provided by professional musicians. AIFEC was founded on the principle that music has the potential to enrich every child’s social, emotional and cognitive development and should be accessible to all children in Hamilton.
            More details can be found on the <a href="https://hamiltonmusiccollective.ca/about/" target="blank">Hamilton Music Collective Website</a>
            </p>
          </div>
          
          <div style={{ width:'100%', height:'10px'}}></div>
        </div>
        )
    }
}

export default OurCharity;
